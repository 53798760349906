
.menu-bs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0;
    padding: 8px 16px;
    background-color: transparent !important;
}

.nv-title {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 56px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}


.link {
    display: inline-block;
    list-style: none;
    padding: 0 8px;
    box-sizing: inherit;
    border: 0 solid #e2e8f0;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    font-family: Tenor Sans;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 2px solid transparent;
    display: block;
    letter-spacing: 2px;
    line-height: 1;
    margin: 0 auto;
    padding: 8px 8px 6px;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.link:hover {
    font-family: Tenor Sans;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 2px solid transparent;
    display: block;
    letter-spacing: 2px;
    line-height: 1;
    margin: 0 auto;
    padding: 8px 8px 8px;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #caccd0;
  }

.active {
    font-family: Tenor Sans;
    letter-spacing: 0px;
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 2px solid transparent;
    display: block;
    letter-spacing: 2px;
    line-height: 1;
    margin: 0 auto;
    padding: 8px 8px 6px;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #ffffff;
}

.show-mobile{
    justify-content: center;    
}

.show-mobile span {
    padding: 0 10px;      
}

.navbar-toggler-icon {
    background-image: none!important;
    background-color: var(--bs-white);
    height: 3px;
    width: 25px;
    margin: 10px 0;
    position: relative;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }
  
  .navbar-toggler-icon::before {
    display: block;
    background-color: var(--bs-white);
    height: 3px;
    content: "";
    position: relative;
    top: -7px;
    transition: all 0.15s ease-out;/*taken down to hide quicker*/
    transform-origin: center;
  }
  
  .navbar-toggler-icon::after {
    display: block;
    background-color: var(--bs-white);
    height: 3px;
    content: "";
    position: relative;
    top: 4px;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }
  
  .navbar-dark .navbar-toggler-icon,
  .navbar-dark .navbar-toggler-icon::before,
  .navbar-dark .navbar-toggler-icon::after {
    background-color: var(--bs-white);
  }
  
  .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    transform: rotate(45deg);
  }
  
  .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
    opacity: 0;
  }
  
  .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
    transform: rotate(-90deg) translateX(7px);
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

@media (max-width: 768px) {
    .menu-container { 
        height: 56px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 88888;
        background-color: #314C67;
        font-size: calc(32px * 0.75);
    }

    .nav-toggle {
        z-index: 99999;
        padding: 10px;
        position: absolute;
        border: 0px;
    }

    .hidden-mobile {
        display: none;
    }

    .show-mobile{
        display: flex;
    }
    .nv-title {
        top: 0;
        position: absolute;
    }
   
    .navbar-nav {
        background-color: #314C67;
        margin-top: 50px;
        height: 100vh;
    }
}

@media (min-width: 768px) {
    .nv-title {
        display: none;
    }
    .menu-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        padding: 8px 16px;
    }

    .show-mobile{
        display: none;
    }

    #basic-nav-dropdown {
        margin-top: -5px;
    }
}

