.App {
  background-image: url(./img/back_800.webp);
  background-color: #314C67;
  -webkit-background-position: top center;
  background-position: top center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: -1;
  top: 0;
  bottom: -120px;
  margin: 0;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.css-9o5by {
  margin: 44px 0px 0px;
  text-align: center;
  font-family: "Tenor Sans";
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.App {
  top: 0;
  overflow: scroll !important;
}


@media (min-width: 1280px) {
  .App {
      background-image: url(./img/back_2000.webp);
  }
}

@media (min-width: 1024px) {
  .App {
      background-image: url(./img/back_1600.webp);
  }
}

@media (min-width: 768px) {
  .App {
      background-image: url(./img/back_1200.webp);
  }
}