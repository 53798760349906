.planbox {
    padding: 16px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.planTimeline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 24px auto 80px auto;
    max-width: 1000px;
    width: 100%;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.planRow,.planRowPar{
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100vw;
}

.planImg{
    margin: 0;
    padding-left: 3rem;
    padding-right: 3rem;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    z-index: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #314C67;
}

picture{
    display: block;
    height: auto;
    max-width: 100%;
    width: auto;
}

.imgElementPlan {
    width: 100%;
    display: block;
    height: auto;
}

.planText{
    margin: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
    z-index: 1;
    padding-bottom: 1rem;
    background: #314C67;
    font-family: Tenor Sans;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
}
.planFecha {
    font-weight: bold;
    font-family: Tenor Sans;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    font-weight: normal;
    text-transform: uppercase;
}

.planText a {
    color: #ffffff;
}

.planEnd{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 100rem;
}

.planRow::before, .planRowPar::before {
    content: '';
    position: absolute;
    left: 50%;
    display: block;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    border-left: 1px dashed #ffffff;
}

@media (max-width: 640px) {
    .planText {
        font-size: calc(14px * 0.875);
    }
}

@media (max-width: 640px) {
    .planFecha {
        font-size: calc(24px * 0.75);
    }
}

@media (min-width: 540px) {
    .planImg, .planText {
        padding-top: inherit;
        padding-bottom: inherit;
        background: transparent;
    }
}

@media (min-width: 540px) {
    .planRow::before {
        content: '';
        position: absolute;
        left: 50%;
        display: block;
        top: 0;
        height: calc(50% - 0.6rem);
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        border-left: 1px dashed #ffffff;
    }
}

@media (min-width: 540px) {
    .planRow::after {
        content: '';
        position: absolute;
        left: 50%;
        display: block;
        height: calc(50% - 0.6rem);
        bottom: 0;
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        border-left: 1px dashed #ffffff;
    }
}


@media (min-width: 540px) {
    .planRowPar {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        max-width: inherit;
    }
    .planRow {
        max-width: inherit;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}
