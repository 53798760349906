.tab_to_do .mapa_tapeo {
    width: 100%;
}

.mapa_tapeo {
    margin-top: 20px;
    margin-bottom: 20px;
}
.tapeo_lista  {
    padding-inline-start: 0px;
}
.tapeo_lista span {
    color:white;
    font-weight: bold;    
}
.tapeo_lista a {
    color:white;
    margin-bottom: 5px;
}
.tapeo_lista li {
    display: grid;
    text-align: center;
    margin-top: 10px;
    border-bottom: 2px dashed white;
}

.nav-link {
    color: white;
}

.nav-link.active {
    background-color: transparent !important;
    border-color: transparent !important;
    color: white !important;
    font-weight: bold;
    margin-top: 5px;
}

.nav-link:focus {
    border-color: transparent;
}


.ver_granada {
    margin-bottom: 10px;
    border-bottom: 2px dashed white;
    text-align: center;
}

.ver_name {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.ver_description {
    margin-bottom: 10px;
}
.ver_links {
    margin-bottom: 10px;
}
.ver_links a{
    color:white;
    font-weight: bold;
}