

div { 
    display: block;
    unicode-bidi: isolate;
}

header {
    text-align: center;
    width: 100%;
    z-index: 1;
}

.header-title {
    line-height: 1;
    margin: 16px auto;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 52px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding-top: 220px;
    padding-bottom: 15px;
}

.header-when {
    line-height: 1;
    margin: 8px 0;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;

}

.countdown {
    line-height: 1;
    margin: 8px 0;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;
}

@media (min-width: 768px) {
    header {
        display: block;
    }
    .header-title {
        padding-top: 320px;
    }

}


.background{
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.picture {
    max-width: 100%;
    max-height: 120px;
    margin-bottom: 20px;
    width: 100%;
}

.bck-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: solid;
}

footer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: auto;
    position: relative;
    width: 100%;
}

.footer {
    letter-spacing: 3.4px;
    line-height: 1;
    
    bottom: 0px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    
}

.footer-letter {
    padding: 0 18px 16px;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 52px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.footer-date {
    padding: 20px 10px 0;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-top: 1px solid #ffffff;
}

.content {
    min-height: 100vh;
    margin-top: 40px;
}

@media (min-width: 768px) {
    .picture {
        display: block;
    }

    .bck-img-mobile{
        display: none;
    }
}

@media (max-width: 768px) {
    .bck-img {
      display: none
    }

    .bck-img-mobile{
        width: 100%;
        margin-top: 40px;
    }

    footer {
        display: flex;
        width: 100%;
        margin: 152px auto;
    }
}

