.home-title{
    margin: 10px 0 0 0;
    text-align: center;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
}
.home-title a {
    color: #ffffff; 
}
.home{
    display: none;
    line-height: 1.3;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 12px auto;
    padding: 0px 16px;
    width: fit-content;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 42px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.home-fecha {
    padding: 0px 36px 20px;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.home-content {
    text-align: center;

}

.home-lugar {
    padding: 20px 36px 0px;
}

.home-mobile-wedding {
    font-size: calc(24px);
    line-height: 1;
    margin: 32px 0px 8px;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.home-mobile-date {
    font-size: calc(18px);
    margin: 8px 0px;
    font-family: "Tenor Sans";
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
}

@media  (max-width: 768px) {
    .home-mobile {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 48px 0px 24px;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .home-mobile {
        display:none
    }
    .home {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 0px 16px;
        flex-direction: row;
        margin: 52px auto 20px;
    }
    .home-fecha {
        border-bottom: none;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        align-items: center;
        padding: 20px 72px 20px 0px;
        width: 315px;
        border-right: 1px solid rgb(255, 255, 255);
    }
    .home-lugar {
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
        padding: 20px 0px 20px 72px;
        width: 315px;
    }
}