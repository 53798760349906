
.day-description{
    margin: 44px 0 40px 0;
    text-align: center;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
}
.day {
    width: 80%;
    padding: 16px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    align-items: center;
    align-content: center;
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}