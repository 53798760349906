.timeline {
    height: 100%;
    position: relative;
}

.card {
    background-image: url(../../img/back_card.webp);
}

.col-card-left {
    background-color: white;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 10px;
    border: 2px solid white;
    
}

.col-card-right {
    background-color: white;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 10px;
    border: 2px solid white;
}

.col-note {
    color: white;
}

.col-card-title {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;    
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.col-card-title-left {
    text-align: right;
}

.col-card-title-right {
    text-align: left;
}

.col-card-subtitle {
    color: white;
    font-size: 1rem;
    font-weight: bold;    
    border-bottom: 2px solid white;
    background-image: trans;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.col-card-subtitle-left {
    text-align: right;
}

.col-card-subtitle-right {
    text-align: left;
}

.col-card-para {
    font-size: 1rem;
    padding: 1rem;
    color: white;
}

.triangle-left {
    width: 0;
    height: 0;
    border-left: 15px solid white;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    margin-top: 10px;
    margin-left: -8px;
    z-index: 1000;
    position: absolute;
}

.triangle-right {
    width: 0;
    height: 0;
    border-right: 15px solid white;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    margin-top: 10px;
    margin-left: 9%;
    z-index: 3000;
    position: absolute;
}

.col-note-left {
    text-align: right;
    margin: 1rem;
}

.col-note-right {
    text-align: left;
    margin: 1rem;
    margin-top: 2rem;
}

.col-card-url-left {
    text-align: right;
}

.col-card-url {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    color: white;
}

.col-card-url-right {
    text-align: left;
}

.col-card-url-left {
    text-align: right;
}

.circle {
    width: 50px;
    height: 50px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-image: url(../../img/back_800.webp);
    border: 3px solid white;
    margin: 0 auto;
    z-index: 1000;
    position: relative;
}

.icon {
    
    z-index: 5000;
    color: white;
    font-size: 1.5rem;
    margin: 0 auto;
    margin-top: .2rem;
    display: table;
    padding-top: .5rem;
}

.line {
    border-left: 3px solid white;
    height: 100%;
    position: absolute;
    left: 50%;
    z-index: 1;
    top: 0;
    bottom: 0;
}

.clearfix {
    clear: both;
}

.column-left {
    float: left;
    width: 45%;
}

.column-right {
    float: right;
    width: 45%;
}

.column-center {
    display: inline-block;
    padding-top: 1rem;
    width: 10%;
}

.numberCircle {
    color: white;
    padding: initial;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 1rem;
    width: min-content;
    height: min-content;
    border-radius: 10px;
    background-image: url(../../img/back_card.webp);
    border: 2px solid white;
    margin-left: 1rem;
    text-decoration: none;
}

@media (max-width: 668px) {
    .hide-mobile {
        display:none;
    }

    .col-img {
        display: none;
    }

    .col-card-title {
        font-size: 1rem;
        font-weight: bold;
    }

    .numberCircle{
        padding: 0px;
        border-style: dashed;
    }
    .line {
        left:52%;
    }

    .col-card-subtitle {
        font-size: 0.7rem;
        border-bottom: 0px solid white;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .col-card-para {
        display: none;
    }

    .col-card-right {
        margin-left: 1.1rem;
        margin-right: 0;
    }

    .col-note-right {
        margin: 2rem;
    }

    .col-note-left {
        margin: 2rem;
    }
}
