
.registry-description{
    margin: 44px 0 40px 0;
    text-align: center;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
}

.registry-link {
    margin: 0px 0 40px 0;
    text-align: center;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 20px;
}

.registry-form {
    display: none;
    background-color: #ffffff;
    width: 70%;
    height: 2000px;
    border-radius: 40px;
}

.registry {
    width: 80%;
    padding: 16px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    align-items: center;
    align-content: center;
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.registry-link-mobile {
    margin: 0px 0 40px 0;
    text-align: center;
    font-family: Tenor Sans;
    color: #ffffff;
    font-size: 20px;
}

@media (min-width: 768px) {

    .registry-link {
        display: block;
    }

    .registry-link-mobile {
        display: none;
    }   
    .registry-form {
        display: block;
    }

}

@media (max-width: 668px) {
    .registry-link {
        display: none;
    }
    .registry-link-mobile {
        display: block;
    }
}