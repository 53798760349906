.logistica {
    padding: 16px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: auto;
    text-align: center;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

ul {
    list-style-type: none;
  }

.legenda {
    font-size: 10px;
    background-color: white;
    color: black;
    padding-top: 20px;
    padding-bottom: 20px;
}

.cuadrado {
    background-size: contain;
    background-position: 0 0;
    background-repeat: no-repeat;
    -webkit-background-size: auto 18px;
    background-size: auto 18px;
    display: block;
    height: 18px;
    float:left;
    width: 18px;
    margin: 3px auto 0 auto;

}
.cuadrado_rojo {
    background-image: url(../img/route_red.png);
    
}

.cuadrado_azul{
    background-image: url(../img/route_blue.png);
}