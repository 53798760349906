.hotel{
    padding: 1px 10px;
    color: white;
    max-width: 550px;
    margin: 0px auto 12px;
    text-align: center;
}

.hotels {
    padding: 16px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.one_hotel {
    padding: 1px 10px;
    text-align: center;
    border-bottom: 1px solid #ffffff;
}
.hotel_description{
    margin-bottom: 10px;
}

.hotel_name {
    line-height: 1;
    margin: 28px 0px;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}
.hotel_website {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 20px 0px;
}
.hotel_website_link{
    line-height: 1.25;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px 10px;
    padding: 13px 36px;
    text-decoration: none;
    transition: all 0.25s ease 0s;
    font-family: "Tenor Sans";
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    color: rgb(49, 76, 103);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
}
.hotel_name a{
    line-height: 1;
    margin: 28px 0px;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.hotel_ubicacion{
    font-style: normal;
    line-height: 1;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.hotel_telefono{
    font-style: normal;
    line-height: 1;
    margin-top: 8px;
    font-family: "Tenor Sans";
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.hotel_ubi{
    margin: 16px 0px;
    display: inline-block;
    width: 80%;
}

@media (max-width: 640px) {
    .hotel_ubicacion {
        font-size: calc(13.5px);
    }
}

@media (max-width: 640px) {
    .hotel_telefono {
        font-size: calc(13.5px);
    }
}